import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "company": "Amazon",
  "title": "Software Engineering Intern",
  "time": "Fall 2022",
  "index": 6,
  "type": "position",
  "hidden": false,
  "splash": "Incoming SWE Intern"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://amazon.com"
      }}>{`Amazon`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` Sept - Dec 2022`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` TBD`}</p>
    <p><strong parentName="p">{`Location:`}</strong>{` Remote in Canada`}</p>
    <p><strong parentName="p">{`Team:`}</strong>{` TBD`}</p>
    <hr></hr>
    <p>{`I will be joining Amazon as a Software Engineering Intern this fall.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      